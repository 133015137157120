import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/core/plugins/bootstrap';
import moment from 'moment'
Vue.config.productionTip = false
import Api from '@/core/Api';
Vue.prototype.$api = Api;
Api.moment = moment;
new Vue({
  router,  
  render: h => h(App),
  beforeMount(){
    Api.toast = this.$bvToast;        
  }
}).$mount('#app')
