import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [  
  {
    path:'/f/:uuid',
    name: 'DocumentCommande',
    component: () => import( "@/views/document/File.vue"),
    meta:{
      logged:false
    }
  }        
];

const router = new VueRouter({
  routes,
});
export default router;
