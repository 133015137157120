
export default {
    url:{    
      api: 'https://api.docu.day',
      app:'http://localhost:8080',    
    },    
    toast:false,
    moment:false,
    ajax(route, params = null, callBack) {
      const url = route.search('http') === 0 ? route : this.url.api + route;
      let the = this;
      let option = {};
      option.headers = {
        "Content-Type":"application/json",        
      }
      if(params != null){
        option.method = "POST",
        option.body = JSON.stringify(params)
      }
      fetch(url,option).then( response => {        
        response.json().then( data =>{
          if(data.status === false){
            console.log(the.toast);
            the.toast.toast(data.data, {
              title: 'Attention',
              autoHideDelay: 5000,
              variant:'warning',
              solid:true              
            });
          }
          the.res = data;
          if(callBack != null) {
            callBack(the.res);
          }
        })
      });
    },    
    now(){
      var n = new Date().getTime();
      n = n/1000;
      return parseInt(n);
    },  
    link(path){
      return this.url.api+path;
    },
    uuid() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },  
  }
  